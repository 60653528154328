<template>
  <div id="TicketPackages">
    <div class="ticket-packages__container">
      <b-container>
        <h2 class="title">
          <!-- Ticket Packages -->
        </h2>
        <div
          v-for="(header, index) in ticketPackagesHeader"
          :key="'header' + index"
          class="header-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <span class="header" v-html="header"></span>
        </div>
      </b-container>
      <div class="cards" v-if="packages">
        <div
          class="cards__container"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          :data-aos-delay="200"
        >
          <div class="title-box">
            <h2
              v-if="
                activeSection == 'courtside' || activeSection == 'playerCafe'
              "
            >
              The
              <span>Access</span>
            </h2>
            <h2 v-else>
              The
              <span>Details</span>
            </h2>
          </div>
          <div class="image-box">
            <img
              :src="require('@/assets/images/' + ticketPackageImages[0])"
              alt="card image"
            />
            <div
              v-if="hours"
              class="menu-overlay"
              @mouseover="hover2 = false"
              @mouseout="hover2 = true"
            >
              <div class="menu-overlay__container">
                <div class="menu-overlay__top">
                  <div class="img-container">
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.308 5.42l4.533-3.878L4.533 0 0 3.879 1.308 5.42zm17.384 0L20 3.88 15.42.047l-1.308 1.542 4.58 3.832zM10 20c2.492 0 4.618-.872 6.379-2.617 1.76-1.744 2.64-3.847 2.64-6.308s-.88-4.564-2.64-6.309C14.619 3.022 12.492 2.15 10 2.15c-2.492 0-4.626.872-6.402 2.616C1.854 6.511.981 8.614.981 11.075c0 2.46.88 4.564 2.64 6.308C5.381 19.128 7.508 20 10 20zm0-1.963c-1.931 0-3.583-.685-4.953-2.056-1.371-1.37-2.056-3.006-2.056-4.906s.685-3.536 2.056-4.907C6.417 4.798 8.069 4.112 10 4.112c1.931 0 3.583.686 4.953 2.056 1.371 1.37 2.056 3.006 2.056 4.907 0 1.931-.685 3.575-2.056 4.93-1.37 1.355-3.022 2.032-4.953 2.032zm3.738-3.13l.748-1.262-3.972-2.337V6.121H9.019v5.935l4.72 2.85z" fill="#FFF" fill-rule="nonzero"/></svg>
                  </div>
                  <div class="menu-overlay__cuisine">
                    <ul
                      v-for="(hour, index) in hours"
                      :key="'option1' + index"
                      class="menu-overlay__list"
                    >
                      <li>{{ hour }}</li>
                    </ul>
                  </div>
                </div>
                <div class="menu-overlay__buttons">
                  <button class="button button-hours">Hours of Access</button>
                </div>
              </div>
            </div>
            <div v-else class="overlay"></div>
          </div>
          <div class="sections">
            <div
              class="section"
              v-for="(pack, index) in packages.theDetails"
              :key="'description' + index"
              v-html="pack['description']"
            ></div>
          </div>
        </div>
        <div
          class="cards__container"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          :data-aos-delay="400"
        >
          <div class="title-box" v-if="activeSection == 'courtside'">
            <h2>
              The
              <span>Hospitality</span>
            </h2>
          </div>
          <div class="title-box" v-else-if="activeSection == 'playerCafe'">
            <h2>
              The
              <span>Service</span>
            </h2>
          </div>
          <div class="title-box third" v-else>
            <h2>The <span>Cuisine</span> | The <span>Drinks</span></h2>
          </div>
          <div class="image-box">
            <img
              :src="require('@/assets/images/' + ticketPackageImages[1])"
              alt="card image"
            />
            <div
              v-if="
                packages.cuisineOverlay.length > 0 ||
                packages.drinkOverlay.length > 0
              "
              class="menu-overlay"
              @mouseover="hover = false"
              @mouseout="hover = true"
            >
              <div class="menu-overlay__container">
                <div class="menu-overlay__top">
                  <div class="img-container">
                    <svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.675 14l1.11-1.092-5.403-5.311 1.13-1.13c.615.285 1.305.334 2.071.149.766-.185 1.462-.58 2.09-1.184.49-.482.847-1.021 1.073-1.62.226-.598.301-1.175.226-1.73a2.325 2.325 0 00-.678-1.388 2.39 2.39 0 00-1.403-.666c-.558-.074-1.139 0-1.741.222a4.586 4.586 0 00-1.638 1.055c-.615.617-1.023 1.301-1.224 2.054-.2.752-.157 1.431.132 2.036L.758 12.908 1.868 14l5.404-5.311L12.675 14zM4.222 7.893l2.203-2.22L.908.286a3.11 3.11 0 00-.8 1.388 2.945 2.945 0 000 1.582c.145.524.411.984.8 1.379l3.314 3.257z" fill="#FFF" fill-rule="nonzero"/></svg>
                  </div>
                  <div class="menu-overlay__drinks" v-if="seen1">
                    <ul class="menu-overlay__list">
                      <!-- <li
                        v-for="(drinks, index) in packages.drinkOverlay"
                        :key="'option1' + index"
                      >
                        {{ drinks["description"] }}
                      </li> -->
                      <li
                        v-for="(drinks, index) in packages.drinkOverlay"
                        :key="'option1' + index"
                        v-html="drinks['description']"
                      ></li>
                    </ul>
                  </div>
                  <div class="menu-overlay__cuisine" v-if="seen2">
                    <ul class="menu-overlay__list">
                      <li
                        v-for="(cuisine, index) in packages.cuisineOverlay"
                        :key="'option2' + index"
                        v-html="cuisine['description']"
                      ></li>
                    </ul>
                  </div>
                </div>
                <div class="menu-overlay__buttons">
                  <button
                    v-if="packages.drinkOverlay.length > 0"
                    v-on:click="drinks"
                    class="button1 button"
                    v-bind:class="{ active1: isActive1, hover: hover }"
                  >
                    The Drinks
                  </button>
                  <button
                    v-if="packages.cuisineOverlay.length > 0"
                    v-on:click="cuisine"
                    class="button2 button"
                    v-bind:class="{ active2: isActive2, hover: hover }"
                  >
                    The Cuisine
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="overlay"></div>
          </div>
          <div class="sections">
            <template v-for="(pack, index) in packages.theCuisine">
              <router-link
                class="section"
                :key="'description' + index"
                v-if="
                  pack['description'].includes('1968 Room') &&
                  activeSection == 'courtside'
                "
                to="/1968room"
              >
                {{ pack["description"] }}
              </router-link>
              <div
                class="section"
                :key="'description1' + index"
                v-else
                v-html="pack['description']"
              ></div>
            </template>
          </div>
        </div>
        <div
          class="cards__container"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          :data-aos-delay="600"
        >
          <div class="title-box">
            <h2 v-if="activeSection == 'playerCafe'">
              The
              <span>View</span>
            </h2>
            <h2 v-else>
              The
              <span>View</span>
            </h2>
          </div>
          <div class="image-box">
            <img
              :src="require('@/assets/images/' + ticketPackageImages[2])"
              alt="card image"
            />
            <div class="overlay"></div>
          </div>
          <div class="sections">
            <template v-for="(pack, index) in packages.theView">
              <router-link
                class="section"
                :key="'description' + index"
                v-if="
                  pack['description'].includes('1968 Room') &&
                  activeSection == 'courtside'
                "
                to="/1968room"
              >
                {{ pack["description"] }}
              </router-link>
              <div
                class="section"
                :key="'description2' + index"
                v-else
                v-html="pack['description']"
              ></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketPackages",
  props: {
    ticketPackagesData: {
      type: Array,
      required: false,
    },
    ticketPackagesHeader: {
      type: Object,
      required: false,
    },
    menuData: {
      type: Array,
      required: false,
    },
    packages: {
      type: Object,
      required: false,
    },
    ticketPackageImages: {
      type: Array,
      required: false,
    },
    hours: {
      type: Array,
      required: false,
    },
    overlay: {
      type: Array,
      required: false,
    },
    activeSection: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      seen1: false,
      seen2: true,
      isActive1: false,
      isActive2: true,
      hover: true,
      hover2: true,
    };
  },
  methods: {
    drinks: function () {
      var vm = this;
      if (this.seen1 === false) {
        this.seen1 = !this.seen1;
        this.isActive1 = !this.isActive1;
        this.seen2 = !this.seen2;
        this.isActive2 = !this.isActive2;
      }
    },
    cuisine: function () {
      var vm = this;
      if (this.seen2 === false) {
        this.seen1 = !this.seen1;
        this.isActive1 = !this.isActive1;
        this.seen2 = !this.seen2;
        this.isActive2 = !this.isActive2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#TicketPackages {
  margin: 20px 0 30px 0;
  .ticket-packages__container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .title {
      font-family: "interstate", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 20px;
      color: $text-black;
      letter-spacing: 0;
      text-align: center;
      // padding: 50px 0;
      border-top: 1px solid #e1e1e1;
    }
    .header-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      font-family: "interstate", sans-serif;
      font-weight: 500;
      color: $text-grey;
      line-height: 35px;
      display: block;
      text-align: center;
      padding: 50px 0;
      width: 80%;
      p {
        text-align: center;
        span {
          color: #418fde;
        }
      }
    }

    // styling for cards
    .cards {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      &__container {
        width: 32%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // box-shadow: 0px 0 20px rgba(0, 0, 0, 0.4);
        box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
        background: #ffffff;
        border-radius: 5px;
        @media (max-width: 1000px) {
          width: 97%;
          margin-bottom: 13px;
        }
        .title-box {
          height: 119px;
          display: flex;
          justify-content: center;
          align-items: center;
          h2 {
            display: flex;
            flex-direction: column;
            font-family: "interstate", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 22px;
            text-transform: uppercase;
            color: $text-grey;
            letter-spacing: 0;
            text-align: center;
            line-height: 23px;
          }
          span {
            font-weight: 700;
            color: $text-black;
          }
        }
        .third {
          h2 {
            display: flex;
            flex-direction: row;
          }
          span {
            margin: 0 5px;
          }
        }
        .image-box {
          position: relative;
          width: 100%;
          img {
            height: 410px;
            width: 100%;
            object-fit: cover;
            @media (max-width: $md) {
              height: 400px;
            }
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-image: linear-gradient(269deg, rgba(0,40,140,0.00) 0%, rgba(0,10,35,0.70) 100%);
          }
          .menu-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: #ffffff;
            // background-image: linear-gradient(269deg, rgba(0,40,140,0.00) 0%, rgba(0,10,35,0.70) 100%);
            z-index: 1;
            &:hover {
              // background: linear-gradient(269deg, rgba(0,40,140,0.70) 1%, rgba(0,10,35,0.70) 100%);
              .menu-overlay__top {
                visibility: visible;
                opacity: 1;
              }
            }
            &::before {
              position: absolute;
              content: "";
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              // background-image: linear-gradient(269deg, rgba(0,40,140,0.70) 1%, rgba(0,10,35,0.70) 100%);
              background-image: linear-gradient(
                269deg,
                rgba(0, 40, 140, 0.4) 1%,
                rgba(65, 143, 222, 0.8) 100%
              );
              z-index: -1;
              transition: opacity 0.5s linear;
              opacity: 0;
            }
            &:hover::before {
              opacity: 1;
            }

            .menu-overlay__top {
              visibility: hidden;
              opacity: 0;
              transition: visibility 0.5s linear, opacity 0.5s linear;
            }
            &__container {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: 30px 0 22px 0;
              // @media (max-width: $lg) {
              //   padding: 25px 0 22px 0;
              // }
              @media (max-width: $md) {
                padding: 5% 0 3% 0;
              }
              .img-container {
                width: 36px;
                height: 36px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 25px;
                @media (max-width: $lg) {
                  margin-bottom: 20px;
                }
              }
              .menu-overlay__top {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: "interstate", sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 11px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 25px;
                text-transform: uppercase;
                padding: 0 30px;
                max-height: 300px;
                overflow: auto;
              }
            }
            &__list {
              list-style: none;
              text-align: center;
            }
            &__buttons {
              font-family: "interstate", sans-serif;
              font-weight: 500;
              font-style: normal;
              font-size: 10px;
              color: $text-black;
              letter-spacing: 0;
              line-height: 20.24px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
              .button {
                box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.15);
                border-radius: 15.4px;
                border: none;
                background: rgba(128, 128, 128, 0.6);
                width: 114.4px;
                height: 24.2px;
                transition: background 0.5s ease;
                outline: none;
                z-index: 9;
                &-hours {
                  background: #ffffff;
                }
              }
              .hover {
                background: #ffffff;
              }
              .button2 {
                margin-left: 11px;
              }
            }
            .active1 {
              background: #ffffff;
            }
            .active2 {
              background: #ffffff;
            }
          }
        }
        .sections {
          // height: 250px;
          display: flex;
          flex-direction: column;
          .section {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "interstate", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 12px;
            color: $text-grey;
            letter-spacing: 0;
            text-align: center;
            line-height: 27px;
            border-bottom: 1px solid #e1e1e1;
            border-radius: 4px;
            padding: 1.5em 2em;
            height: 91px;
            text-decoration: none;
          }
          .last {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#TicketPackages {
  .header {
    span {
      color: #418fde;
    }
  }
}
</style>