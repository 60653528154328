<template>
  <div id="SelectDate">
    <b-container>
      <div class="title">
        <h2>Select your date for</h2>
        <h3>{{ dateCardTitle }}</h3>
      </div>
      <div class="filters">
        <span
          class="filter"
          @click="setFilter('ALL'), resetSelect()"
          :class="{ active: currentFilter === 'ALL' }"
        >
          All Dates
        </span>
        <span
          class="filter"
          @click="setFilter('AVAILABLE'), resetSelect()"
          :class="{ active: currentFilter === 'AVAILABLE' }"
        >
          Dates Available
        </span>
        <span
          class="filter"
          @click="setFilter('NOT'), resetSelect()"
          :class="{ active: currentFilter === 'NOT' }"
        >
          Dates Not Available
        </span>
        <select v-model="selectedDate" @change="selectChange()">
          <option value="ALL">Show all dates</option>
          <option
            v-for="(dateCard, index) in dateCard"
            :key="'dateCard' + index"
            :value="dateCard.date"
          >
            {{ dateCard.date }}
          </option>
        </select>
      </div>

      <!-- Need the following comment to use v-if and v-for in one tag. Need to create computed! -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <transition-group class="datesgrid" name="datesanimate">
        <div
          class="datecard"
          v-show="selectedDate === dateCard.date || selectedDate === 'ALL'"
          v-if="currentFilter === dateCard.category || currentFilter === 'ALL'"
          v-for="(dateCard, index) in dateCard"
          :key="'dateCard' + index"
        >
          <div class="datecard__date">
            <svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.074 12.35c.317 0 .593-.117.826-.35a1.13 1.13 0 00.35-.826V3.026a1.13 1.13 0 00-.35-.826 1.13 1.13 0 00-.826-.35H9.5V.674H8.324V1.85H3.676V.674H2.5V1.85h-.574c-.327 0-.604.117-.833.35a1.141 1.141 0 00-.343.826v8.148c0 .317.114.593.343.826.229.233.506.35.833.35h8.148zm0-1.176H1.926V4.776h8.148v6.398zm-1.148-1.148V7.1H6v2.926h2.926z" fill="#418FDE" fill-rule="nonzero"/></svg>
            <span>{{ dateCard.date }}</span>
          </div>
          <div
            class="datecard__content"
            :style="{ 'background-image': 'url(' + dateCard.bgImage + ')' }"
          >
            <div
              class="datecard__content__price"
              v-if="dateCard.button !== 'SOLD OUT'"
              v-html="dateCard.price"
            ></div>

            <h2 class="datecard__content__title" v-html="dateCard.title"></h2>

            <p class="datecard__content__subtitle">
              {{ dateCard.subtitle }}
            </p>

            <button
              class="datecard__content__btn"
              :class="{ disabled: dateCard.isDisabled }"
              type="button"
              v-b-modal="'offer-' + index"
            >
              {{ dateCard.button }}
            </button>
          </div>
          <b-modal :id="'offer-' + index" centered hide-header hide-footer>
            <div class="title">Select Package</div>
            <div class="bottom">
              <div class="left">
                <div class="overlay"></div>
                <div class="date">
                  <div class="day">{{ dateCard.modalDay }}</div>
                  <div class="number">{{ dateCard.modalDate }}</div>
                  <div class="month">{{ dateCard.modalMonth }}</div>
                </div>
                <div class="info">
                  <div class="day">
                    DAY {{ dateCard.info.day }}, SESSION
                    {{ dateCard.info.session }} -
                  </div>
                  <ul>
                    <li
                      v-for="(point, ind) in dateCard.info.points"
                      :key="'point' + ind"
                    >
                      {{ point }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div
                  class="session"
                  v-for="(session, i) in dateCard.sessions"
                  :key="'session' + i"
                >
                  <div class="title">{{ session.title }}</div>
                  <a
                    v-if="session.button == 'Buy' && session.ticketMasterLink"
                    class="button"
                    type="button"
                    :href="session.ticketMasterLink"
                    target="_blank"
                  >
                    {{ session.button }}
                  </a>
                  <!-- <button
                    v-else-if="session.button == 'Buy'"
                    class="button"
                    type="button"
                    :data-fevo-offer-id="session.offerId"
                    :data-fevo-sub-offer-id="
                      'location.href' + session.subOfferId
                    "
                  >
                    {{ session.button }}
                  </button> -->
                  <button
                    v-else-if="session.button == 'Buy'"
                    class="button"
                    type="button"
                    @click="openFevo(session.offerId)"
                  >
                    {{ session.button }}
                  </button>
                  <div v-else class="button disabled">
                    {{ session.button }}
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
        <div
          class="datecardMobile"
          :class="{ disabled: dateCard.isDisabled }"
          v-show="selectedDate === dateCard.date || selectedDate === 'ALL'"
          v-if="currentFilter === dateCard.category || currentFilter === 'ALL'"
          v-for="(dateCard, index) in dateCard"
          :key="'dateCardMobile' + index"
        >
          <div class="left">
            <img :src="dateCard.bgImage" alt="side image" />
            <div v-if="dateCard.isDisabled" class="sold-out">sold<br />out</div>
          </div>
          <div class="datecardMobile__middle">
            <div class="datecardMobile__middle--date">
              <svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.074 12.35c.317 0 .593-.117.826-.35a1.13 1.13 0 00.35-.826V3.026a1.13 1.13 0 00-.35-.826 1.13 1.13 0 00-.826-.35H9.5V.674H8.324V1.85H3.676V.674H2.5V1.85h-.574c-.327 0-.604.117-.833.35a1.141 1.141 0 00-.343.826v8.148c0 .317.114.593.343.826.229.233.506.35.833.35h8.148zm0-1.176H1.926V4.776h8.148v6.398zm-1.148-1.148V7.1H6v2.926h2.926z" fill="#418FDE" fill-rule="nonzero"/></svg>
              <span>{{ dateCard.date }}</span>
            </div>
            <h2
              class="datecardMobile__middle--title"
              v-html="dateCard.title"
            ></h2>
            <p class="datecardMobile__middle__subtitle">
              {{ dateCard.subtitle }}
            </p>
          </div>
          <button
            class="datecardMobile__btn"
            :class="{ disabled: dateCard.isDisabled }"
            type="button"
            v-b-modal="'offerMobile-' + index"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.012 12.024l6.012-6.012L6.012 0 4.95 1.062l4.194 4.212H0V6.75h9.144L4.95 10.962z" fill-rule="nonzero"/></svg>
          </button>
          <b-modal
            :id="'offerMobile-' + index"
            centered
            hide-header
            hide-footer
          >
            <div class="title">Select Session</div>
            <div class="bottom">
              <div class="left">
                <div class="overlay"></div>
                <div class="date">
                  <div class="day">{{ dateCard.modalDay }}</div>
                  <div class="number">{{ dateCard.modalDate }}</div>
                  <div class="month">{{ dateCard.modalMonth }}</div>
                </div>
                <div class="info">
                  <div class="day">DAY {{ dateCard.info.day }} -</div>
                  <ul>
                    <li
                      v-for="(point, ind) in dateCard.info.points"
                      :key="'point' + ind"
                    >
                      {{ point }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div
                  class="session"
                  v-for="(session, i) in dateCard.sessions"
                  :key="'session' + i"
                >
                  <div class="title" v-html="session.title"></div>
                  <!-- <button
                    v-if="session.button == 'Buy'"
                    class="button"
                    type="button"
                    :data-fevo-offer-id="session.offerId"
                    :data-fevo-sub-offer-id="session.subOfferId"
                  >
                    {{ session.button }}
                  </button> -->
                  <a
                    v-if="session.button == 'Buy' && session.ticketMasterLink"
                    class="button"
                    type="button"
                    :href="session.ticketMasterLink"
                    target="_blank"
                  >
                    {{ session.button }}
                  </a>
                  <button
                    v-else-if="session.button == 'Buy'"
                    class="button"
                    type="button"
                    @click="openFevo(session.offerId)"
                  >
                    {{ session.button }}
                  </button>
                  <div v-else class="button disabled">
                    {{ session.button }}
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </transition-group>
      <p class="postscript">*pricing/availability are subject to change</p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SelectDate",
  props: {
    dateCard: {
      type: Array,
      required: true,
    },
    dateCardTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: "ALL",
      currentFilter: "ALL",
    };
  },
  methods: {
    setFilter(filter) {
      this.currentFilter = filter;
    },
    resetSelect() {
      this.selectedDate = "ALL";
    },
    selectChange() {
      this.currentFilter = "ALL";
      let activeFilter = document.querySelector(".filter");
      activeFilter.classList.remove("active");
    },
    disableReload(e) {
      e.preventDefault();
    },
    openFevo(offerId) {
      window.GMWidget.open(offerId);
    },
  },
};
</script>

<style lang="scss" scoped>
#SelectDate {
  .title {
    margin: 40px 0;
    h2 {
      text-align: center;
      font-weight: 700;
      color: $text-black;
      font-size: 20px;
    }
    h3 {
      text-align: center;
      font-weight: 500;
      font-style: italic;
      color: $text-black;
      font-size: 20px;
    }
  }
  .filters {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
    width: 97.5%;
    padding: 20px 0;
    margin: auto;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    @media screen and (max-width: $lg) {
      width: 100%;
    }
    @media screen and (max-width: $sm) {
      padding: 20px 0 70px 0;
      justify-content: center;
    }
    .filter {
      font-weight: 700;
      font-size: 11px;
      color: $text-black;
      text-transform: uppercase;
      margin-right: 30px;
      cursor: pointer;
      @media screen and (max-width: $md) {
        margin-right: 20px;
      }
      @media screen and (max-width: $sm) {
        font-size: 9px;
        margin: 0 5px;
        text-align: center;
      }
      &.active {
        color: $cta-blue;
      }
    }
    select {
      position: absolute;
      right: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 3px;
      border: none;
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
      font-size: 11px;
      min-width: 142px;
      padding: 10px 0 10px 12px;
      background-color: #fff;
      background-image: url("~@/assets/icons/products/icon_select-down-arrow.png");
      background-repeat: no-repeat;
      background-position: 95% 50%;
      color: $text-grey;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: $sm) {
        min-width: 100%;
        top: 50px;
      }
      &:focus {
        box-shadow: 0px 1px 5px $cta-blue;
        outline: none;
      }
    }
  }
  .datesgrid {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    justify-content: center;
    padding-top: 40px;
    .datecard {
      width: 23.5%;
      max-width: 23.5%;
      height: 278px;
      max-height: 278px;
      // margin: 10px 0;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
      margin: 10px 6px 0 6px;
      @media screen and (max-width: $lg) {
        width: 40%;
        max-width: 40%;
      }
      @media screen and (max-width: $md) {
        width: 45%;
        max-width: 45%;
      }
      @media screen and (max-width: $sm) {
        // width: 90%;
        // max-width: 90%;
        display: none;
      }
      &__date {
        display: flex;
        align-items: center;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 700;
        color: $text-grey;
        padding: 13px 16px;
        svg {
          margin-right: 12px;
        }
      }
      &__content {
        position: relative;
        height: 227px;
        background-size: cover;
        background-position: center center;
        &__price {
          font-size: 10px;
          color: $text-black;
          position: absolute;
          background-color: #fff;
          border-radius: 20px;
          padding: 5px 20px;
          top: 32px;
          left: 19px;
          span {
            color: $cta-blue;
          }
        }
        &__title {
          position: absolute;
          font-style: italic;
          color: #fff;
          font-size: 14px;
          line-height: 21px;
          top: 71px;
          left: 19px;
          width: 75%;
        }
        &__subtitle {
          position: absolute;
          color: #fff;
          font-size: 9px;
          line-height: 15px;
          text-transform: uppercase;
          top: 121px;
          left: 19px;
          width: 75%;
        }
        &__btn {
          position: absolute;
          width: 90%;
          text-align: center;
          background-color: hsla(210, 70%, 56%, 0.6);
          bottom: 10px;
          left: 0;
          right: 0;
          margin: auto;
          padding: 17px 0;
          font-weight: 700;
          font-size: 11px;
          color: #fff;
          text-transform: uppercase;
          border-radius: 3px;
          border: none;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: $cta-blue;
            text-decoration: none;
          }
          &:focus {
            outline: none;
          }
          &.disabled {
            pointer-events: none;
            background-color: rgba(255, 255, 255, 0.3);
            &:hover {
              background-color: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }
    .datecardMobile {
      @media (min-width: ($sm + 1)) {
        display: none;
      }
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
      height: 150px;
      width: 359.609px;
      max-width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      margin-bottom: 29px;
      &.disabled {
        position: relative;
        box-shadow: none;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(#e9e9e9, 0.5);
        }
      }
      .left {
        position: relative;
        height: 100%;
        width: 27.6%;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
        .sold-out {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-family: "interstate", sans-serif;
          font-weight: 800;
          font-size: 9px;
          text-transform: uppercase;
          text-align: center;
        }
      }
      &__middle {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 46.25%;
        padding: 0 5px;
        &--date {
          svg {
            width: 10.5px;
            margin-right: 11.8px;
          }
          span {
            font-family: "interstate", sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 9px;
            color: #7f7f7f;
            letter-spacing: 0;
          }
        }
        &--title {
          font-size: 14px;
          color: #373737;
          @media (max-width: 420px) {
            font-size: 12px;
          }
        }
      }
      &__btn {
        background: #418fde;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12.76%;
        border-radius: 0 3px 3px 0;
        border: none;
        &.disabled {
          pointer-events: none;
          // background: #7f7f7f;
          background: #c9c9c9;
          svg {
            fill: #e6e6e6;
          }
        }
        svg {
          fill: #ffffff;
          width: 12.6px;
        }
      }
    }
  }
  .datesanimate-enter-active {
    transition: all 0.3s ease-in-out;
  }
  .datesanimate-leave-active {
    transition: all 0.3s ease-in-out;
    display: none;
  }
  .datesanimate-enter,
  .datesanimate-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  .postscript {
    margin: 20px 0 0 15px;
    font-family: "interstate", sans-serif;
    font-size: 12px;
    font-style: italic;
    @media (max-width: $lg) {
      margin-left: 65px;
    }
    @media (max-width: 767px) {
      margin-left: 20px;
    }
    @media (max-width: 576px) {
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
.modal {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    269deg,
    rgba(0, 40, 140, 0.7) 1%,
    rgba(0, 10, 35, 0.7) 100%
  );
  .modal-dialog {
    width: fit-content;
    min-width: fit-content;
    max-width: fit-content;
    height: fit-content;
    margin: 1.75rem auto;

    .modal-content {
      width: fit-content;
      height: fit-content;
      .modal-body {
        padding: 0 5px 5px 5px;
      }
    }
  }
  .title {
    font-family: "interstate", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #373737;
    text-align: center;
    margin: 41px 0;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    @media (max-width: 900px) {
      flex-direction: column;
    }
    .left {
      position: relative;
      display: flex;
      flex-direction: row;
      background: url("~@/assets/images/bg_fevo-modal.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 3px;
      @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: center center;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background-image: linear-gradient(
          270deg,
          rgba(27, 115, 204, 0) 0%,
          rgba(65, 143, 222, 0.13) 100%
        );
        // z-index: 1;
      }
      .date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10.5px 19.5px;
        padding: 40.5px 53px 39.5px 53px;
        border-radius: 50%;
        height: 175px;
        width: 175px;
        border: 1px solid #ffffff;
        z-index: 2;
        @media (max-width: 450px) {
          margin-top: 30.5px;
        }
        .day,
        .month {
          font-family: "interstate", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
        .day {
          margin-bottom: 10px;
        }
        .month {
          margin-top: 10px;
        }
        .number {
          font-family: "interstate", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 50px;
          line-height: 41px;
          color: #ffffff;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 180px;
        margin: 0 23px 0 11px;
        z-index: 2;
        @media (max-width: 450px) {
          margin-top: 10px;
        }
        .day {
          font-family: "interstate", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 11px;
          line-height: 13px;
          color: #ffffff;
          margin-bottom: 10px;
        }
        ul {
          padding-left: 15px;
          color: #ffffff;
          font-family: "interstate", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      @media (max-width: 900px) {
        margin-top: 2px;
      }
      @media (max-width: 450px) {
        flex-direction: column;
      }
      .session {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ececec;
        border-radius: 3px;
        // padding: 57px 16px 60px 17px;
        height: 196px;
        width: 213px;
        // margin: 0 0 0 2px;
        &:first-child {
          margin: 0 2px;
          @media (max-width: 900px) {
            margin-left: 0;
          }
          @media (max-width: 450px) {
            margin: 0 0 2px 0;
          }
        }
        // &.single {
        //   width: 360px;
        // }
        // &.double {
        //   width: 180px;
        // }

        .title {
          font-family: "interstate", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: #373737;
          margin: 0 0 15px 0;
          max-width: 170px;
        }
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #418fde;
          border: none;
          border-radius: 3px;
          width: 180px;
          height: 45px;
          font-family: "interstate", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          line-height: 13px;
          color: #ffffff;
          outline: none;
          text-decoration: none;
          &.disabled {
            background-color: transparent;
            border: none;
            border-radius: 3px;
            width: 180px;
            height: 45px;
            font-family: "interstate", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 13px;
            line-height: 13px;
            border: 1px solid #e14b4b;
            color: #e14b4b;
          }
        }
      }
    }
  }
}
</style>